/**
 * Can be used to feature content when we want a full image background and live text overlayed. This card provides an editorial aesthetic to a page.
 *
 * @module views/components/ImageBackgroundCard
 * @memberof -Common
 */
import './ImageBackgroundCard.scss';

import React, { useRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import GridContainer from '@ulta/core/components/GridContainer/GridContainer';
import Text from '@ulta/core/components/Text/Text';
import UltaAsset from '@ulta/core/components/UltaAsset/UltaAsset';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { constants } from '@ulta/core/utils/constants/constants';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';

import ActionGroup from '@ulta/modules/ActionGroup/ActionGroup';

import Tag from '@ulta/modules/Tag/Tag';

/**
 * Represents a ImageBackgroundCard component
 *
 * @method
 * @param { ImageBackgroundCardProps} props - React properties passed from composition
 * @returns ImageBackgroundCard
 */
export const ImageBackgroundCard = function( props ){
  const { breakpoint } = useDeviceInflection();
  const isMobile = breakpoint?.isSmallDevice();
  const {
    image,
    imageMobile,
    tag,
    eyebrow,
    headline,
    subcopy,
    textAlignment,
    actionGroup,
    root,
    rootMargin,
    threshold
  } = props;
  const imageBackgroundCardRef = useRef( null );
  const textCentered = ( textAlignment === 'center-aligned' );
  useIntersectionObserver( imageBackgroundCardRef, {
    root: root,
    rootMargin: rootMargin,
    threshold: threshold
  }, handleIntersection( props ) );

  if( !headline || !image ){
    return null;
  }

  return (
    <div className='ImageBackgroundCard'
      ref={ imageBackgroundCardRef }
    >
      { image &&
      <div className='ImageBackgroundCard__backgroundImageContainer'>
        <GridContainer>
          <div className='ImageBackgroundCard__backgroundImage'>
            { isMobile &&
              <UltaAsset
                { ...( imageMobile ) }
              />
            }
            { !isMobile &&
              <UltaAsset
                { ...( image ) }
              />
            }
          </div>
        </GridContainer>
      </div>
      }
      <div className={
        classNames( 'ImageBackgroundCard__wrapper', {
          [`ImageBackgroundCard__wrapper--${textAlignment}`]:textAlignment
        } )
      }
      >
        <GridContainer>
          <div className='ImageBackgroundCard__textSection'>
            { tag && !eyebrow &&
              <div className='ImageBackgroundCard__tag'>
                <Tag
                  variant='default'
                  style='white'
                  message={ tag }
                />
              </div>
            }
            { eyebrow &&
              <div className='ImageBackgroundCard__eyebrow'>
                <Text
                  htmlTag='p'
                  textStyle='eyebrow'
                  textAlign={ textCentered ? 'center' : 'left' }
                >
                  { eyebrow }
                </Text>
              </div>
            }
            { headline &&
              <div className={
                classNames( 'ImageBackgroundCard__headline', {
                  'ImageBackgroundCard__headline--tagNoEyebrow': tag && !eyebrow
                } )
              }
              >
                <Text
                  htmlTag='h2'
                  textStyle='title-4'
                  textAlign={ textCentered ? 'center' : 'left' }
                >
                  { headline }
                </Text>
              </div>
            }
            { subcopy &&
              <div className='ImageBackgroundCard__subcopy'>
                <Text
                  htmlTag='p'
                  textStyle='body-1'
                  color='neutral-800'
                  textAlign={ textCentered ? 'center' : 'left' }
                >
                  { subcopy }
                </Text>
              </div>
            }
            { ( ( actionGroup?.action1?.label ) || ( actionGroup?.action2?.label ) ) && (
              <div className='ImageBackgroundCard__actions'>
                <ActionGroup
                  action1={ actionGroup.action1 }
                  action2={ actionGroup.action2 }
                  action1Style={ actionGroup.action1Style }
                  action2Style={ actionGroup.action2Style }
                  centerActions={ textCentered }
                />
              </div>
            ) }
          </div>
        </GridContainer>
      </div>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef ImageBackgroundCardProps
 * @type {object}
 * @property {object} image - Sets the desktop image
 * @property {object} imageMobile - Sets the background Image for mobile view
 * @property {string} tag - Sets the tag
 * @property {string} eyebrow - Sets the eyebrow text
 * @property {string} headline - Sets the headline
 * @property {string} subcopy - Sets the subcopy
 * @property {string} textAlignment - Sets the alignment of the text within the component
 * @property {string} actionGroup - Set the action1 and/or action2 for the Message
 */
export const propTypes =  {
  image: PropTypes.object,
  imageMobile: PropTypes.object,
  tag: PropTypes.string,
  eyebrow: PropTypes.string,
  headline: PropTypes.string,
  subcopy: PropTypes.string,
  textAlignment: PropTypes.string,
  actionGroup: PropTypes.object
};

/**
  * Default values for passed properties
  * @type {object}
  */
export const defaultProps = {
  ...constants.INTERSECTION_OBSERVER_OPTIONS
};

ImageBackgroundCard.propTypes = propTypes;
ImageBackgroundCard.defaultProps = defaultProps;

export default ImageBackgroundCard;
